
import {computed, defineComponent, ref, watch} from "vue";
import {
  alertController,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform,
  loadingController,
  onIonViewWillEnter
} from "@ionic/vue";
import {useStore} from "vuex";
import {useOrderApi} from "@/domain/service/api/order.api";
import OrderListComponent from "@/app/view/order/components/OrderListComponent.vue";
import {useStockApi} from "@/domain/service/api/stock.api";
import {formatDate} from "@/domain/helper/date.helper";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";
import {rwatch} from "@/app/service/ionic/reactive";
import {loading} from "@/app/service/ionic/loading";

export default defineComponent({
  name: "OrderListPage",
  components: {
    OrderListComponent,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonHeader,
    IonToolbar,
    IonTitle,
  },
  setup() {
    const store = useStore();
    const {finalizeAllOrdersCommit} = useOrderApi();
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const type = ref({type: 'active'})
    const orders2 = computed(() => store.getters.allActiveOrders);
    const ordersWeekDate = (dateInTimeStamp: number) => {
      if (!dateInTimeStamp)
        return;
      return formatDate(dateInTimeStamp, 'DD-MM-YYYY');
    }

    const isSubmitting = ref<boolean>(false);
    const finalizeAllOrders = async () => {

      if (isSubmitting.value)
        return;

      isSubmitting.value = true;

      const loading = await loadingController
          .create({
            message: 'Finalizando Ordenes',
            duration: 5000,
          });

      await loading.present();

      await finalizeAllOrdersCommit();

      await loading.dismiss();
      isSubmitting.value = false;

    };
    const onFinalizeALl = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Finalización de ordenes',
            message: '¿Estas seguro de que quieres finalizar todas las ordenes de planificación?',
            buttons: [
              {
                text: 'Finalizar todos',
                role: 'success',
                cssClass: 'danger',
                handler: () => {
                  finalizeAllOrders();
                },
              },
              {
                text: 'Atras',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel:');
                },
              },
            ],
          });
      await alert.present();

      const {role} = await alert.onDidDismiss();
    }


    onIonViewWillEnter(async () => {
      await useMaterialApi().fetchAll();
      await useStockApi().fetchAll();
      await useDeliveryApi().fetchAll();
    });

    return {
      // searchWord,
      orders2,
      onFinalizeALl,
      type,
      isTablet,
      ordersWeekDate,
    };
  }
})
